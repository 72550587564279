<template>
  <div class="contents register wedding">
    <div class="title flexB">
      <h2>컨텐츠 관리</h2>
    </div>
    <div class="box one">
      <div class="inputBox">
        <div class="flexL">
          <p class="bold">
            카테고리
            <span>*</span>
          </p>
          <el-select v-model="category" placeholder="선택" class="searchCondition">
            <el-option key="결혼준비" label="결혼준비" value="결혼준비"></el-option>
            <el-option key="웨딩장소" label="웨딩장소" value="웨딩장소"></el-option>
            <el-option key="웨딩컬렉션" label="웨딩컬렉션" value="웨딩컬렉션"></el-option>
            <el-option key="허니문" label="허니문" value="허니문"></el-option>
            <el-option key="혼수컬렉션" label="혼수컬렉션" value="혼수컬렉션"></el-option>
            <el-option key="썬데이살롱" label="썬데이 살롱" value="썬데이살롱"></el-option>
            <el-option key="더플래너TV" label="더플래너 TV" value="더플래너TV"></el-option>
          </el-select>
        </div>
        <div class="flexL">
          <p class="bold">
            컨텐츠 제목
            <span>*</span>
          </p>
          <input type="text" v-model="title" />
        </div>
        <div class="flexL" v-if="category == '더플래너TV'">
          <p class="bold">
            youtube
            <span>*</span>
          </p>
          <input type="text" v-model="url" placeholder="공유 - 퍼가기 링크를 입력해 주세요." />
        </div>
        <div class="flexL" v-else>
          <p class="bold">
            url
            <span>*</span>
          </p>
          <div class="fullW archive">
            <input type="text" v-model="url" placeholder="네이버 블로그 모바일 주소로 입력해 주세요." />
            <em class="notice" v-if="category != '더플래너TV'">ex) <b> https://m.</b>blog.naver.com/</em>
          </div>
        </div>

        <div class="flexL">
          <p class="bold">
            썸네일
            <span>*</span>
          </p>
          <div class="imgBox">
            <ul>
              <li v-if="thumbnail">
                <img :src="thumbnail" alt="업로드이미지" />
                <a class="cancel" @click="handleDeleteFile(thumbnail)">
                  <img src="@/assets/images/icon_svg/ic_delete_wh.svg" alt="icon" />
                </a>
              </li>
              <li v-else>
                <label for="imgUpload">
                  <span class="material-icons addBtn">add</span>
                </label>
                <input type="file" ref="thumbnail" id="imgUpload" @change="sendFile()" accept="image/*" />
              </li>
            </ul>
            <em class="notice">대표사진은 3:2 비율로 업로드 됩니다. ex) 500 * 333</em>
          </div>
        </div>
      </div>

      <div class="buttonWrap">
        <router-link to="/admin/archivemanage">목록</router-link>
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button v-if="editMode" class="line" @click="handleDelete">삭제</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { uploadFile, deleteFile, deletePostFile } from "@/api/file";
import { createArchivePost, fetchArchivePostDetail, upadateArchivePost, removeArchivePost } from "@/api/admin";
import { format } from "@/mixins/format";

export default {
  mixins: [format],

  data() {
    return {
      editMode: false,
      moment: moment,
      modalVisible: false,
      placeVisible: false,
      file: "",
      archiveId: "",

      category: "",
      title: "",
      type: "",
      url: "",
      thumbnail: "",
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "5-3");
  },
  mounted() {
    if (this.$route.query.id) {
      this.editMode = true;
      this.archiveId = this.$route.query.id;
      this.getArchivePost();
    }
  },
  methods: {
    getArchivePost() {
      fetchArchivePostDetail(this.archiveId).then((res) => {
        if (res.data.status == 200) {
          this.title = res.data.data.title;
          this.category = res.data.data.category;
          this.url = res.data.data.url;
          this.thumbnail = res.data.data.thumbnail;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    sendFile() {
      if (this.thumbnail) {
        this.$alert("썸네일 삭제 후 업로드 해주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      this.file = this.$refs.thumbnail.files[0];

      if (this.file == undefined) {
        return;
      }
      let formdata = new FormData();
      formdata.append("file", this.file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            this.thumbnail = res.data.url;
            this.file = "";
            document.getElementById("imgUpload").value = "";
            // input file에서 흔적 지우기
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            return;
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    handleDeleteFile(url) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      })
        .then(() => {
          if (this.editMode) {
            deletePostFile(this.archiveId, url).then((res) => {
              if (res.data.status == 200) {
                this.thumbnail = "";
              } else {
                this.$alert(res.data.message, {
                  confirmButtonText: "확인",
                  center: true,
                });
              }
            });
          } else {
            deleteFile(url).then((res) => {
              if (res.data.status == 200) {
                this.thumbnail = "";
              } else {
                this.$alert(res.data.message, {
                  confirmButtonText: "확인",
                  center: true,
                });
              }
            });
          }
        })
        .catch(() => {});
    },
    handleDelete() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        removeArchivePost(this.archiveId).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: "컨텐츠이 삭제 되었습니다.",
              type: "success",
            });
            this.$router.replace("/admin/archivemanage");
          }
        });
      });
    },
    submit() {
      if (this.category == "") {
        this.$alert("카테고리를 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.title == "") {
        this.$alert("제목을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.category != "더플래너 TV" && this.url == "") {
        this.$alert("블로그 url을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.category == "더플래너 TV" && this.url == "") {
        this.$alert("유튜브 url을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.thumbnail == "") {
        this.$alert("썸네일을 등록해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }

      let data = {
        title: this.title,
        category: this.category,
        url: this.url,
        thumbnail: this.thumbnail,
      };

      if (this.editMode) {
        data.archiveId = this.archiveId;
        upadateArchivePost(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("컨텐츠 정보가 반영 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/archivemanage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      } else {
        createArchivePost(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("컨텐츠 등록이 완료 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/archivemanage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      }
    },
  },
};
</script>
